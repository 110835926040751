<template>
  <div>
    <label class="font-weight-bolder font-medium-4 mb-1">{{ $t('Contact Defaults') }}</label>
    <b-row>
      <b-col md="6">
        <label class="font-weight-bolder">{{ $t('Default Contact Type') }}</label>
        <component
          :is="contactDefaults[field].type"
          v-for="field in ['contact_type_is_account_holder', 'contact_type_is_billing', 'contact_type_is_can_place_orders', 'contact_type_is_can_place_shipping',]"
          :key="field"
          :ref="`${field}_ref`"
          v-model="contactDefaultsFormForm[field]"
          v-bind="getProps(field)"
          :is-editable="!$can(ACCESS_ABILITY_FOR_SYSTEM_CUSTOMER_DEFAULTS.action,ACCESS_ABILITY_FOR_SYSTEM_CUSTOMER_DEFAULTS.subject) || isEdit"
        />
      </b-col>
      <b-col md="6">
        <div>
          <label
            :for="contactDefaults['contact_default_status']"
            class="font-weight-bolder"
          >Default Status</label>
          <component
            :is="contactDefaults[field].type"
            v-for="field in ['contact_default_status']"
            :key="field"
            v-model="contactDefaultsFormForm[field]"
            :is-b-form-group="contactDefaults[field].isBFormGroup"
            v-bind="getProps(field)"
            :is-editable="!$can(ACCESS_ABILITY_FOR_SYSTEM_CUSTOMER_DEFAULTS.action,ACCESS_ABILITY_FOR_SYSTEM_CUSTOMER_DEFAULTS.subject) || isEdit"
          />
          <label class="font-weight-bolder">{{ $t('Default Preferred contact method') }}</label>
          <b-form-group
            v-slot="{ ariaDescribedby }"
          >
            <b-form-radio
              v-for="radio in contactsDefaultsRadioGroup"
              :key="radio.value"
              v-model="contactDefaultsFormForm['contact_preferred_contact_method'] "
              class="custom-radio-group-input"
              :aria-describedby="ariaDescribedby"
              :name="radio.value"
              :value="radio.value"
              :disabled="!$can(ACCESS_ABILITY_FOR_SYSTEM_CUSTOMER_DEFAULTS.action,ACCESS_ABILITY_FOR_SYSTEM_CUSTOMER_DEFAULTS.subject) || isEdit"
            >
              {{ $t(radio.title) }}
            </b-form-radio>
          </b-form-group>

        </div>
      </b-col>
    </b-row>
  </div>
</template>
<script>
import { required } from '@/libs/validations/validations'
import ErrorAlert from '@/views/components/ErrorAlert.vue'
import { ValidationObserver } from 'vee-validate'
import {
  BButton, BCol, BForm, BRow, BFormCheckbox, BFormGroup, BFormRadio,
} from 'bootstrap-vue'
// eslint-disable-next-line import/no-named-as-default
import config from '../../defaultsConfig'

export default {
  name: 'ContactDefaults',
  components: {
    ErrorAlert,
    ValidationObserver,
    BForm,
    BRow,
    BCol,
    BButton,
    BFormCheckbox,
    BFormGroup,
    BFormRadio,
  },
  props: {
    isEdit: {
      type: Boolean,
      default: true,
    },
  },
  data() {
    return {
      error: {},
      required,
    }
  },
  computed: {
    contactDefaultsFormForm() {
      return this.$store.state[this.MODULE_NAME].ContactDefaultsForm
    },
    paymentOptions() {
      return this.$store.state.listModule.paymentOptionList
    },
    customerRules() {
      return this.$store.state.listModule.customerRulesList
    },
  },
  methods: {
    isChecked(id, listArray) {
      return Array.isArray(listArray) && listArray.includes(String(id))
    },
    toggleIds(id, field) {
      if (this.businessEntityDefaultsForm[field].includes(String(id))) {
        this.businessEntityDefaultsForm[field] = this.businessEntityDefaultsForm[field].filter(pId => pId !== String(id))
      } else {
        this.businessEntityDefaultsForm[field].push(String(id))
      }
    },
    getProps(fieldName) {
      return {
        is: this.contactDefaults[fieldName].type,
        field: this.contactDefaults[fieldName],
        name: fieldName,
      }
    },
  },
  setup() {
    const MODULE_NAME = 'settings-customer'
    const MODULE_NAME_CLONE = 'cloneData'
    const { contactDefaults, contactsDefaultsRadioGroup, ACCESS_ABILITY_FOR_SYSTEM_CUSTOMER_DEFAULTS } = config()
    return {
      MODULE_NAME,
      MODULE_NAME_CLONE,
      contactDefaults,
      contactsDefaultsRadioGroup,
      ACCESS_ABILITY_FOR_SYSTEM_CUSTOMER_DEFAULTS,
    }
  },
}
</script>
<style lang="scss">
.custom-radio-group-input {
  padding-left: 10px !important;
  margin-bottom: 8px !important;

  & .custom-control-label {
    position: relative !important;
    width: 100% !important;

    &::before {
      left: 95.2% !important;
    }
  }
}
</style>
