<template>
  <div>
    <label class="font-weight-bolder font-medium-4">{{ $t('Search') }}</label>
    <b-row>
      <b-col md="6">
        <div
          v-for="field in ['is_searches_show_inactive_records']"
          :key="field"
          style="height: 70px"
        >
          <b-skeleton
            height="40px"
            style="border-radius: 5px"
          />
        </div>
      </b-col>
    </b-row>
    <label class="font-weight-bolder font-medium-4">{{ $t('Business Entity Defaults') }}</label>
    <b-row>
      <b-col md="6">
        <div
          v-for="field in ['status', 'currency_id', 'price_tier_id', 'tax_rule_id',]"
          :key="field"
          style="height: 70px"
        >
          <span class="inputLabel">{{ businessEntityDefaults[field].label }}</span>
          <b-skeleton
            height="40px"
            style="border-radius: 5px"
          />
        </div>
        <label class="inputLabel">{{ $t('Payment options') }}</label>
        <div
          v-for="field in ['1', '2', '3', '4']"
          :key="field"
          style="height: 30px; display: flex; align-items: center; justify-content: space-between; margin-bottom: 10px"
        >
          <b-skeleton
            width="200px"
            heigth="20px"
          />
          <b-skeleton
            width="18px"
            height="18px"
            style="border-radius: 1px"
          />
        </div>
        <label class="inputLabel">{{ $t('Customer Rules') }}</label>
        <div
          v-for="field in ['1313']"
          :key="field"
          style="height: 30px; display: flex; align-items: center; justify-content: space-between"
        >
          <b-skeleton
            width="200px"
            heigth="20px"
          />
          <b-skeleton
            width="18px"
            height="18px"
            style="border-radius: 1px"
          />
        </div>

      </b-col>
      <b-col md="6">
        <div
          v-for="field in ['delivery_type_id', 'discount', 'credit_limit',]"
          :key="field"
          style="height: 70px"
        >
          <span class="inputLabel">{{ businessEntityDefaults[field].label }}</span>
          <b-skeleton
            height="40px"
            style="border-radius: 5px"
          />
        </div>
        <label class="inputLabel">{{ $t('Default Selected Business Transactions') }}</label>
        <div
          v-for="field in ['allow_to_rent_from_subrent', 'allow_to_purchase_from_supplier']"
          :key="field"
          style="height: 30px; display: flex; align-items: center; justify-content: space-between"
        >
          <span class="inputLabel">{{ businessEntityDefaults[field].label }}</span>
          <b-skeleton
            width="18px"
            height="18px"
            style="border-radius: 1px"
          />
        </div>
      </b-col>
    </b-row>
  </div>

</template>
<script>
import {
  BRow, BSkeleton, BCol,
} from 'bootstrap-vue'
import config from '../defaultsConfig'

export default {
  name: 'Skeleton',
  components: {
    BCol,
    BRow,
    BSkeleton,
  },
  setup() {
    const { searchDefaults, businessEntityDefaults } = config()
    return {
      searchDefaults,
      businessEntityDefaults,
    }
  },
}
</script>
