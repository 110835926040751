import {
  CHECKBOX_LEF, L_ACTIVE_CHECK_LF_BOX, L_CURRENCY_MASK_INPUT, SELECT_INPUT, TEXT_INPUT,
} from '@/views/components/DynamicForm/constants'

export default function config() {
  const ACCESS_ABILITY_FOR_SYSTEM_CUSTOMER_DEFAULTS = { action: 'change', subject: 'Defaults' }

  const searchDefaults = {
    is_searches_show_inactive_records: {
      type: CHECKBOX_LEF,
      label: 'Active',
    },
    customer_id: {
      type: SELECT_INPUT,
      fParams: { is_active: 1 },
      fSearchParams: { is_active: 1 },
      store: 'customersList',
      rules: 'required',
      options: {
        label: 'cust_name_dba',
      },
      label: 'Default Customer',
    },
  }
  const businessEntityDefaults = {
    status: {
      type: L_ACTIVE_CHECK_LF_BOX,
      store: 'statusList',
      rules: 'required',
      isBFormGroup: false,
    },
    currency_id: {
      type: SELECT_INPUT,
      label: 'Default Currency',
      store: 'currencyList',
      firstSelect: true,
      rules: 'required',
      options: {
        label: 'iso_currency',
      },
    },
    warehouse_id: {
      type: SELECT_INPUT,
      label: 'Default Warehouse',
      store: 'warehouseList',
      firstSelect: true,
      rules: 'required',
      options: {
        label: 'label',
      },
    },
    payment_term_id: {
      type: SELECT_INPUT,
      fParams: { is_active: 1 },
      fSearchParams: { is_active: 1 },
      label: 'Default Payment Term',
      store: 'paymentTermList',
      options: {
        label: 'label',
      },
      rules: 'required',
    },
    price_tier_id: {
      type: SELECT_INPUT,
      label: 'Default Price Tier',
      store: 'priceTierList',
      options: {
        label: 'label',
      },
      rules: 'required',
    },
    tax_rule_id: {
      type: SELECT_INPUT,
      label: 'Default Tax Rules',
      store: 'taxRuleList',
      fParams: { is_active: 1 },
      fSearchParams: { is_active: 1 },
      options: {
        label: 'label',
      },
      rules: 'required',
    },
    delivery_type_id: {
      type: SELECT_INPUT,
      label: 'Default Delivery Type',
      store: 'dispatchAndReturnMethodsList',
      options: {
        label: 'name',
      },
      rules: 'required',
    },
    discount: {
      type: TEXT_INPUT,
      label: 'Default Discount',
      rules: 'numeric|required|max_value:100',
      placeholder: 'Type...',
    },
    credit_limit: {
      type: TEXT_INPUT,
      label: 'Default Credit Limit',
      rules: 'integer|required',
      placeholder: 'Type...',
    },
    allow_to_rent_from_subrent: {
      type: CHECKBOX_LEF,
      label: 'Allow to Rent From (sub-rent)',
      rules: 'required',
    },
    allow_to_purchase_from_supplier: {
      type: CHECKBOX_LEF,
      label: 'Allow to Purchase from (supplier)',
      rules: 'required',
    },
    // def_payment_options_id: {
    //   type: CHECKBOX,
    //   label: 'Payment options',
    //   rules: 'required',
    // },
    '3rd_party_payment_order': {
      type: CHECKBOX_LEF,
      label: '3rd party Payment Order',
    },
  }
  const contactDefaults = {
    contact_type_is_account_holder: {
      type: CHECKBOX_LEF,
      label: 'Account Holder',
      rules: 'required',
    },
    contact_type_is_billing: {
      type: CHECKBOX_LEF,
      label: 'Billing',
      rules: 'required',
    },
    contact_type_is_can_place_orders: {
      type: CHECKBOX_LEF,
      label: 'Can Place Orders',
      rules: 'required',
    },
    contact_type_is_can_place_shipping: {
      type: CHECKBOX_LEF,
      label: 'Shipping',
      rules: 'required',
    },
    contact_default_status: {
      type: L_ACTIVE_CHECK_LF_BOX,
      name: 'Active',
      store: 'statusList',
      rules: 'required',
      isBFormGroup: false,
    },
  }
  const addressDefaults = {
    address_type_is_account_holder: {
      type: CHECKBOX_LEF,
      label: 'Account Holder',
      rules: 'required',
    },
    address_type_is_billing: {
      type: CHECKBOX_LEF,
      label: 'Billing',
      rules: 'required',
    },
    address_type_is_shipping: {
      type: CHECKBOX_LEF,
      label: 'Shipping',
      rules: 'required',
    },
    address_status: {
      type: L_ACTIVE_CHECK_LF_BOX,
      store: 'statusList',
      rules: 'required',
      isBFormGroup: false,
    },
  }
  const customerVerificationDefaults = {
    verification_decuctibles_always_require: {
      type: CHECKBOX_LEF,
      label: 'Always require deposit for deductible',
    },
    verification_default_status_id: {
      type: SELECT_INPUT,
      label: 'Default Rental Status',
      store: 'verificationDefaultStatus',
      options: {
        label: 'text',
      },
      rules: 'required',
    },
    verification_first_rental: {
      type: L_CURRENCY_MASK_INPUT,
      label: 'First rental require deposit over total default',
      placeholder: 'Type...',
      rules: 'required',
    },
    verification_recurrent_customers: {
      type: L_CURRENCY_MASK_INPUT,
      label: 'Recurrent customers default ',
      placeholder: 'Type...',
      rules: 'required',
    },
  }
  const contactsDefaultsRadioGroup = [
    {
      value: 'email',
      title: 'Email',
    },
    {
      value: 'phone',
      title: 'Phone call',
    },
  ]

  return {
    searchDefaults,
    contactDefaults,
    addressDefaults,
    businessEntityDefaults,
    contactsDefaultsRadioGroup,
    customerVerificationDefaults,
    ACCESS_ABILITY_FOR_SYSTEM_CUSTOMER_DEFAULTS,
  }
}
