<template>
  <div class="mt-3">
    <label class="font-weight-bolder font-medium-4 mb-1">{{ $t('Customer Verification Defaults') }}</label>
    <b-row>
      <b-col md="6">
        <component
          :is="customerVerificationDefaults[field].type"
          v-for="field in ['verification_default_status_id']"
          :key="field"
          :ref="`${field}_ref`"
          v-model="customerVerificationDefaultsForm[field]"
          v-bind="getProps(field)"
          :is-editable="!$can(ACCESS_ABILITY_FOR_SYSTEM_CUSTOMER_DEFAULTS.action,ACCESS_ABILITY_FOR_SYSTEM_CUSTOMER_DEFAULTS.subject) || isEdit"
        />
        <label class="font-weight-bolder">{{ $t('Deductibles default') }}</label>
        <component
          :is="customerVerificationDefaults[field].type"
          v-for="field in ['verification_decuctibles_always_require']"
          :key="field"
          :ref="`${field}_ref`"
          v-model="customerVerificationDefaultsForm[field]"
          v-bind="getProps(field)"
          :is-editable="!$can(ACCESS_ABILITY_FOR_SYSTEM_CUSTOMER_DEFAULTS.action,ACCESS_ABILITY_FOR_SYSTEM_CUSTOMER_DEFAULTS.subject) || isEdit"
        />
      </b-col>
      <b-col md="6">
        <div>
          <component
            :is="customerVerificationDefaults[field].type"
            v-for="field in ['verification_first_rental', 'verification_recurrent_customers']"
            :key="field"
            v-model="customerVerificationDefaultsForm[field]"
            v-bind="getProps(field)"
            :is-editable="!$can(ACCESS_ABILITY_FOR_SYSTEM_CUSTOMER_DEFAULTS.action,ACCESS_ABILITY_FOR_SYSTEM_CUSTOMER_DEFAULTS.subject) || isEdit"
          />
        </div>
      </b-col>
    </b-row>
  </div>
</template>
<script>
import { required } from '@/libs/validations/validations'
import ErrorAlert from '@/views/components/ErrorAlert.vue'
import { ValidationObserver } from 'vee-validate'
import {
  BButton, BCol, BForm, BRow, BFormCheckbox, BFormGroup, BFormRadio,
} from 'bootstrap-vue'
// eslint-disable-next-line import/no-named-as-default
import config from '../../defaultsConfig'

export default {
  name: 'CustomerVerificationDefaults',
  components: {
    ErrorAlert,
    ValidationObserver,
    BForm,
    BRow,
    BCol,
    BButton,
    BFormCheckbox,
    BFormGroup,
    BFormRadio,
  },
  props: {
    isEdit: {
      type: Boolean,
      default: true,
    },
  },
  data() {
    return {
      error: {},
      required,
    }
  },
  computed: {
    customerVerificationDefaultsForm() {
      return this.$store.state[this.MODULE_NAME].CustomerVerificationDefaultsForm
    },
  },
  methods: {
    getProps(fieldName) {
      return {
        is: this.customerVerificationDefaults[fieldName].type,
        field: this.customerVerificationDefaults[fieldName],
        name: fieldName,
      }
    },
  },
  setup() {
    const MODULE_NAME = 'settings-customer'
    const MODULE_NAME_CLONE = 'cloneData'
    const { customerVerificationDefaults, ACCESS_ABILITY_FOR_SYSTEM_CUSTOMER_DEFAULTS } = config()
    return {
      MODULE_NAME,
      MODULE_NAME_CLONE,
      customerVerificationDefaults,
      ACCESS_ABILITY_FOR_SYSTEM_CUSTOMER_DEFAULTS,
    }
  },
}
</script>
