<template>
  <div>
    <label class="font-weight-bolder font-medium-4 mb-1">{{ $t('Address Defaults') }}</label>
    <b-row>
      <b-col md="6">
        <label class="font-weight-bolder">{{ $t('Default Address Type') }}</label>
        <component
          :is="addressDefaults[field].type"
          v-for="field in ['address_type_is_account_holder', 'address_type_is_billing', 'address_type_is_shipping']"
          :key="field"
          :ref="`${field}_ref`"
          v-model="addressDefaultsFormForm[field]"
          v-bind="getProps(field)"
          :is-editable="!$can(ACCESS_ABILITY_FOR_SYSTEM_CUSTOMER_DEFAULTS.action,ACCESS_ABILITY_FOR_SYSTEM_CUSTOMER_DEFAULTS.subject) || isEdit"
        />
      </b-col>
      <b-col md="6">
        <div>
          <label
            :for="addressDefaults['address_status']"
            class="font-weight-bolder"
          >Default Status</label>
          <component
            :is="addressDefaults[field].type"
            v-for="field in ['address_status']"
            :key="field"
            v-model="addressDefaultsFormForm[field]"
            :is-b-form-group="addressDefaults[field].isBFormGroup"
            v-bind="getProps(field)"
            :is-editable="!$can(ACCESS_ABILITY_FOR_SYSTEM_CUSTOMER_DEFAULTS.action,ACCESS_ABILITY_FOR_SYSTEM_CUSTOMER_DEFAULTS.subject) || isEdit"
          />
        </div>
      </b-col>
    </b-row>
  </div>
</template>
<script>
import { required } from '@/libs/validations/validations'
import ErrorAlert from '@/views/components/ErrorAlert.vue'
import { ValidationObserver } from 'vee-validate'
import {
  BButton, BCol, BForm, BRow, BFormCheckbox, BFormGroup, BFormRadio,
} from 'bootstrap-vue'
// eslint-disable-next-line import/no-named-as-default
import config from '../../defaultsConfig'

export default {
  name: 'AddressDefaults',
  components: {
    ErrorAlert,
    ValidationObserver,
    BForm,
    BRow,
    BCol,
    BButton,
    BFormCheckbox,
    BFormGroup,
    BFormRadio,
  },
  props: {
    isEdit: {
      type: Boolean,
      default: true,
    },
  },
  data() {
    return {
      error: {},
      required,
    }
  },
  computed: {
    addressDefaultsFormForm() {
      return this.$store.state[this.MODULE_NAME].AddressDefaultsForm
    },
  },
  methods: {
    getProps(fieldName) {
      return {
        is: this.addressDefaults[fieldName].type,
        field: this.addressDefaults[fieldName],
        name: fieldName,
      }
    },
  },
  setup() {
    const MODULE_NAME = 'settings-customer'
    const MODULE_NAME_CLONE = 'cloneData'
    const { addressDefaults, ACCESS_ABILITY_FOR_SYSTEM_CUSTOMER_DEFAULTS } = config()
    return {
      MODULE_NAME,
      addressDefaults,
      MODULE_NAME_CLONE,
      ACCESS_ABILITY_FOR_SYSTEM_CUSTOMER_DEFAULTS,
    }
  },
}
</script>
